<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <div
        class="border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
        @click="$router.go(-1)"
      >
        <IconChevronLeft />
      </div>
      <h1 class="text-700 font-bold">{{ $t('Menu.Management.Invoice') }}</h1>
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.Invoice.Action.CreateInvoice')"
        @click="createInvoice"
      />
    </div>
    <div v-if="item" class="w-full">
      <Card v-if="invoiceKeys" class="flex-col" :title="$t('Management.Invoice.SectionInvoice')">
        <template v-slot:content>
          <div class="flex items-center justify-end gap-2">
            <InputElement
              v-model="copyInvoiceId"
              type="text"
              :placeholder="$t('Management.Invoice.SectionInvoice.CopyFromExisting.Placeholder')"
            />
            <ButtonElement
              class="flex-shrink-0"
              variant="outline"
              size="md"
              :text="$t('Management.Invoice.Action.CopyFromExisting')"
              @click="copyInvoice"
            />
          </div>

          <form
            @submit.prevent="submitForm"
            class="grid grid-cols-1 flex-col gap-2 pt-8 sm:grid-cols-4 border-t border-b mt-8 border-1 border-gray-200"
          >
            <div class="mb-4 flex flex-col" v-for="key in invoiceKeys" :key="`video-${key}`">
              <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
              <InputElement
                v-model="item[key]"
                type="text"
                :placeholder="key"
                :disabled="isDisabledForSeparateInvoice(key)"
              />
            </div>
          </form>

          <div class="mt-4 flex flex-col gap-4">
            <div class="gap-4 flex">
              <ButtonElement
                class="flex-shrink-0"
                variant="outline"
                size="md"
                :text="$t('Management.Invoice.SectionInvoice.AddLine')"
                @click="addLine"
              />
              <ButtonElement
                class="flex-shrink-0"
                variant="outline"
                size="md"
                :text="$t('Management.Invoice.SectionInvoice.AddLinesFromVideoIds')"
                @click="addLinesFromVideoIds"
              />
              <div class="flex flex-row items-center space-x-2 text-300">
                <input
                  type="checkbox"
                  id="checkbox-separate-invoices"
                  name="checkbox-separate-invoices"
                  v-model="createSeparateInvoices"
                />
                <label for="checkbox-separate-invoices">Create separate invoices for each line</label>
              </div>
              <div class="flex flex-row items-center space-x-2 text-300">
                <input type="checkbox" id="checkbox-set-paid" name="checkbox-set-paid" v-model="setInvoicesToPaid" />
                <label for="checkbox-set-paid">Set invoice(s) to paid</label>
              </div>
            </div>
            <div v-if="createdInvoiceIds && createdInvoiceIds.length" class="flex gap-1 text-200 bg-green-300">
              <div class="flex flex-row items-center gap-1">
                <span>Download language:</span>
                <select v-model="downloadLanguage" class="w-24">
                  <option v-for="language in languages" :key="language" :value="language">
                    {{ language.toUpperCase() }}
                  </option>
                </select>
              </div>

              <span>Created invoices:</span>
              <div
                v-for="createdInvoiceId in createdInvoiceIds"
                :key="createdInvoiceId"
                class="cursor-pointer flex flex-row items-center gap-1"
              >
                <span
                  @click="openInNew({ name: 'Invoice', params: { invoiceID: createdInvoiceId } })"
                  class="font-semibold underline"
                  >{{ createdInvoiceId }}</span
                >
                <div class="inline-block">
                  <span>(</span>
                  <span class="font-semibold underline" @click="downloadPdf($event, createdInvoiceId, downloadLanguage)"
                    >Download</span
                  >
                  <span>)</span>
                </div>
              </div>
            </div>

            <div v-if="!lines.length" class="text-200">{{ $t('Management.Invoice.SectionInvoice.NoLines') }}</div>
            <div v-else class="flex flex-col text-200">
              <div
                v-for="(line, index) in lines"
                :key="index"
                class="flex items-start gap-4 p-2"
                :class="{
                  'bg-red-400': line.amount <= 0,
                  'even:bg-neutral-50': line.amount > 0
                }"
              >
                <div class="flex flex-col gap-1">
                  <InputElement
                    v-model="line.description"
                    type="text"
                    placeholder="Description"
                    class="max-w-xl w-screen"
                  />
                  <span class="cursor-pointer text-100" @click="line.description = descriptionTemplate('video')"
                    >{{ index }} - Video</span
                  >
                </div>
                <InputElement v-model="line.amount" type="number" class="w-24" placeholder="Amount" />
                <InputElement v-model="line.quantity" type="number" class="w-24" placeholder="Quantity" />
                <InputElement v-model="line.tax_rate" type="number" class="w-24" placeholder="Tax rate" />
                <InputElement v-model="line.purchase_id" type="number" class="w-32" placeholder="Purchase ID" />
                <InputElement v-model="line.purchase_credit_id" type="number" class="w-32" placeholder="P-Credit ID" />
                <InputElement v-model="line.organization_plan_id" type="number" class="w-32" placeholder="O-Plan ID" />

                <div class="mt-2 cursor-pointer text-red-800 hover:text-red-500" @click="removeLine(line.id)">
                  <IconTrash />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'InvoiceCreate',
  components: {
    Card
  },
  data() {
    return {
      item: null,
      copyInvoiceId: '',
      lines: [],
      createSeparateInvoices: false,
      setInvoicesToPaid: true,
      createdInvoiceIds: [],
      languages: ['en', 'nl', 'de', 'es'],
      downloadLanguage: 'en'
    };
  },
  computed: {
    invoiceKeys() {
      return [
        'company',
        'name',
        'address',
        'postalcode',
        'city',
        'create_date',
        'organization_id',
        'user_id',
        'update_date',
        'expiry_date',
        'period_start',
        'period_end'
      ];
    }
  },
  mounted() {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    this.item = {
      create_date: new Date().toISOString().split('T')[0],
      update_date: new Date().toISOString().split('T')[0],
      expiry_date: oneMonthFromNow.toISOString().split('T')[0]
    };

    for (let i = 0; i < this.invoiceKeys.length; i += 1) {
      if (!this.item[this.invoiceKeys[i]]?.length) {
        this.item[this.invoiceKeys[i]] = '';
      }
    }
  },
  methods: {
    async copyInvoice() {
      if (!this.copyInvoiceId?.length) {
        return;
      }

      const invoice = await this.$api.get(`/admin/management/invoice/${this.copyInvoiceId}`);

      if (!invoice) {
        this.$api.message('Invoice not found');
        return;
      }

      const dateFields = ['create_date', 'update_date', 'expiry_date', 'period_start', 'period_end'];
      for (let i = 0; i < dateFields.length; i += 1) {
        if (invoice[dateFields[i]]?.length) {
          invoice[dateFields[i]] = invoice[dateFields[i]].split('T')[0];
        }
      }

      const newItem = {};
      for (let i = 0; i < this.invoiceKeys.length; i += 1) {
        if (!this.item[this.invoiceKeys[i]]?.length) {
          newItem[this.invoiceKeys[i]] = invoice[this.invoiceKeys[i]];
        }
      }

      this.item = {
        ...this.item,
        ...newItem
      };
    },

    addLine() {
      this.lines.push({
        id: Math.random().toString(36).substring(7),
        description: '',
        amount: 0,
        quantity: 1,
        tax_rate: 21,
        purchase_id: null,
        purchase_credit_id: null,
        organization_plan_id: null
      });
    },

    async addLinesFromVideoIds() {
      const videoIds = (prompt('Enter video IDs separated by comma') ?? '')
        .split(',')
        .map((id) => id.trim())
        .filter((id) => id.length);

      if (!videoIds?.length) {
        return;
      }

      const promises = [];
      for (let i = 0; i < videoIds.length; i += 1) {
        const promise = this.$api.get(`/admin/management/video/${videoIds[i]}`).catch(() => null);
        promises.push(promise);
      }

      const videos = await Promise.all(promises);
      for (let i = 0; i < videos.length; i += 1) {
        const video = videos[i];
        if (!video) {
          this.lines.push({
            id: Math.random().toString(36).substring(7),
            description: `Video ${videoIds[i]}`,
            amount: 0,
            quantity: 1,
            tax_rate: 21,
            purchase_id: null,
            purchase_credit_id: null,
            organization_plan_id: null
          });
          continue;
        }

        let minutePrice = video.plan?.minute_price ?? 0;

        if (video.general?.human_assistance) {
          minutePrice = video.plan?.human_price ?? 0;

          if (video.plan?.per_language && video.video?.target_languages?.length) {
            if (video?.language_prices?.length > 0) {
              minutePrice = 0;
              for (let j = 0; j < video.language_prices.length; j += 1) {
                const price = video?.language_prices[j];
                if (price.language === video.video?.language) {
                  minutePrice += price.caption_price;
                  continue;
                }

                if (price.translation_price > 0) {
                  minutePrice += price.translation_price;
                }
              }
            } else {
              const amountOfLanguages = Math.max(1, video.video?.target_languages.split(',')?.length ?? 1);
              minutePrice *= amountOfLanguages;
            }
          }

          if (minutePrice === 0) {
            minutePrice = video.plan?.human_price ?? 0;
          }
        }

        this.lines.push({
          id: Math.random().toString(36).substring(7),
          description: `${video.video.original_filename}\\n${video.plan?.title} - € ${minutePrice.toFixed(
            2
          )} per minute`,
          amount: minutePrice * Math.ceil(video.video.duration_seconds / 60),
          quantity: 1,
          tax_rate: 21,
          purchase_id: video.purchase.id,
          purchase_credit_id: null,
          organization_plan_id: null
        });
      }
    },

    removeLine(id) {
      this.lines = this.lines.filter((line) => line.id !== id);
    },

    isDisabledForSeparateInvoice(key) {
      if (!this.createSeparateInvoices) {
        return false;
      }

      const disabledFields = ['create_date', 'update_date', 'period_start', 'period_end', 'expiry_date'];
      return disabledFields.includes(key);
    },

    descriptionTemplate(type) {
      if (type === 'video') {
        return 'trimmed.mp4\\nMachine-made - € 2,00 per minute';
      }

      return '';
    },

    openInNew(routeData) {
      const resolvedRoute = this.$router.resolve(routeData);
      window.open(resolvedRoute.href, '_blank');
    },

    async createInvoice() {
      const optionalFields = ['organization_id', 'user_id', 'period_start', 'period_end'];
      for (let i = 0; i < this.invoiceKeys.length; i += 1) {
        if (optionalFields.includes(this.invoiceKeys[i])) {
          continue;
        }

        if (!this.item[this.invoiceKeys[i]]?.length) {
          this.$api.message(`${this.invoiceKeys[i].replace(/_/g, ' ')} is required`);
          return;
        }
      }

      if (!this.lines.length) {
        this.$api.message('At least one line is required');
        return;
      }

      const response = await this.$api.post(`/admin/management/invoice/create`, {
        invoice: this.item,
        lines: this.lines.map((line) => ({
          description: line.description,
          amount: line.amount,
          quantity: line.quantity,
          tax_rate: line.tax_rate,
          purchase_id: line.purchase_id,
          purchase_credit_id: line.purchase_credit_id,
          organization_plan_id: line.organization_plan_id
        })),
        create_separate_invoices: this.createSeparateInvoices ? 1 : 0,
        set_invoices_to_paid: this.setInvoicesToPaid ? 1 : 0
      });

      if (!response?.success) {
        this.$api.message(response?.message ?? 'Failed to create invoice');
        return;
      }

      if (response?.invoice_ids?.length === 1) {
        this.$router.push({ name: 'Invoice', params: { invoiceID: response.invoice_ids[0] } });
      } else {
        this.createdInvoiceIds = response?.invoice_ids ?? [];
      }
      this.$api.message('Changes saved successfully');
    },

    downloadPdf(event, invoiceId, language) {
      const link = `${this.$store.getters.apiBaseURL}/invoice/${invoiceId}?language=${language}${
        event.ctrlKey || event.metaKey ? '&link=1' : ''
      }`;

      if (event.ctrlKey || event.metaKey) {
        window.open(link, '_blank');
      } else {
        window.location = link;
      }
    }
  }
};
</script>
