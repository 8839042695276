<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <div
        class="border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
        @click="$router.go(-1)"
      >
        <IconChevronLeft />
      </div>
      <h1 class="text-700 font-bold">{{ $t('Menu.Management.Invoice') }} #{{ $route.params.invoiceID }}</h1>
      <EntityIcon entity="invoice" />
      <PaymentStatusIcon v-if="item" :status="item.status" />
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.Invoice.Action.Save')"
        @click="saveChanges"
      />
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t('Management.Invoice.Action.OpenActions')"
        @click="actionsVisible = !actionsVisible"
      />
    </div>
    <div v-if="item" class="w-full">
      <div class="grid grid-cols-1 flex-col gap-8 pt-8 sm:grid-cols-2">
        <Card class="flex-col" :title="$t('Management.Invoice.SectionGeneral')">
          <template v-slot:content>
            <div
              v-for="key in generalKeys"
              :key="`general-${key}`"
              class="flex w-full items-start justify-between text-300 even:bg-gray-200"
            >
              <span class="text-neutral-700">{{ key.replace(/_/g, ' ') | capitalize }}</span>
              <span
                v-if="key === 'organization' && item[key] !== null"
                class="cursor-pointer text-blue-500 underline"
                @click="$router.push({ name: 'Organization', params: { organizationID: item[key].id } })"
                >{{ item[key].id }} - {{ item[key].name }} - {{ item[key].email }}</span
              >
              <span v-else-if="key === 'amount'">{{ (item[key] * 100) | money }}</span>
              <span
                v-else-if="key.indexOf('_date') > -1 || key === 'created' || key === 'updated' || key === 'deleted'"
                >{{ item[key] | date }}</span
              >
              <div class="flex flex-col" v-else-if="key === 'items'">
                <span
                  v-for="(item, index) of item[key]"
                  :key="`invoice-item-${index}`"
                  :class="{
                    'cursor-pointer text-blue-500 underline': item.video_id > 0
                  }"
                  @click="navigateInvoiceItem(item)"
                  >{{ item.description }}</span
                >
              </div>
              <span
                v-else
                :class="{
                  'font-mono text-200': key.indexOf('_id') > 0 || key.indexOf('_uuid') > 0
                }"
                >{{ item[key] }}</span
              >
            </div>

            <ButtonElement
              class="mt-8 flex-shrink-0"
              variant="outline"
              size="md"
              :text="$t('Management.Invoice.TogglePreview')"
              @click="togglePreview"
            />

            <div class="mt-8">
              <span class="text-300 font-semibold">{{ $t('Management.Invoice.DownloadPdf') }}</span>
              <div class="mt-2 flex w-full items-center gap-x-2">
                <ButtonElement
                  v-for="(language, index) of languages"
                  :key="index"
                  class="flex-shrink-0"
                  variant="outline"
                  size="sm"
                  :text="language.toUpperCase()"
                  @click="downloadPdf($event, language)"
                />
              </div>
            </div>

            <span
              v-if="item.transactions && item.transactions.length > 0"
              class="mt-8 text-300 font-bold text-neutral-700"
              >Transactions</span
            >
            <div
              v-for="(value, index) in item.transactions || []"
              :key="`transactions-${index}`"
              class="grid w-full grid-cols-5 items-center justify-between text-200 even:bg-gray-200"
              :class="{
                'font-bold': value.status === 'paid'
              }"
            >
              <a
                :href="`https://my.mollie.com/dashboard/org_9453701/payments/${value.payment_id}`"
                class="underline"
                target="_blank"
                >{{ value.payment_id }}</a
              >
              <span>{{ value.created | date('DD-MM-YYYY HH:mm') }}</span>
              <span>{{ value.status }}</span>
            </div>
          </template>
        </Card>

        <div class="flex flex-col gap-8">
          <Card v-if="invoiceKeys" class="flex-col" :title="$t('Management.Invoice.SectionInvoice')">
            <template v-slot:content>
              <form @submit.prevent="submitForm">
                <div class="mb-4 flex flex-col" v-for="key in invoiceKeys" :key="`video-${key}`">
                  <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                  <InputElement v-model="item[key]" type="text" :placeholder="key" />
                </div>
              </form>
            </template>
          </Card>
        </div>
      </div>

      <div v-if="preview" class="mt-8 flex w-full items-center justify-center">
        <iframe
          class="h-screen w-full md:w-1/2"
          :src="`${$store.getters.apiBaseURL}/invoice/${item.id}?link=1`"
        ></iframe>
      </div>
    </div>

    <ActionsPanel
      v-if="item"
      :invoice="item"
      :visible="actionsVisible"
      @hide="actionsVisible = false"
      @refresh="refresh"
      @change="change"
    />
  </div>
</template>

<script>
import set from 'lodash/set';
import Card from '@/components/Card.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';

export default {
  name: 'Invoice',
  components: {
    Card,
    ActionsPanel
  },
  data() {
    return {
      item: null,
      actionsVisible: false,
      preview: false,
      languages: ['en', 'nl', 'de', 'es']
    };
  },
  computed: {
    generalKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = ['id', 'purchase_id', 'organization_id', 'company', 'expiry_date', 'transactions'];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    },
    invoiceKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'purchase_id',
        'organization_id',
        'user_id',
        'create_date',
        'update_date',
        'expiry_date',
        'period_start',
        'period_end',
        'credit_on_paid',
        'items',
        'amount',
        'status',
        'organization',
        'transactions'
      ];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    togglePreview() {
      this.preview = !this.preview;
    },

    async refresh() {
      this.item = await this.$api.get(`/admin/management/invoice/${this.$route.params.invoiceID}`);
    },

    async change(data) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        set(this.item, key, data[key]);
      }

      this.saveChanges();
    },

    async saveChanges() {
      await this.$api.post(`/admin/management/invoice/${this.$route.params.invoiceID}`, {
        invoice: this.item
      });

      this.refresh();
      this.$api.message('Changes saved successfully');
    },

    navigateInvoiceItem(item) {
      if (!item.video_id) {
        return;
      }

      this.$router.push({ name: 'Video', params: { videoID: item.video_id } });
    },

    downloadPdf(event, language) {
      const link = `${this.$store.getters.apiBaseURL}/invoice/${this.$route.params.invoiceID}?language=${language}${
        event.ctrlKey || event.metaKey ? '&link=1' : ''
      }`;

      if (event.ctrlKey || event.metaKey) {
        window.open(link, '_blank');
      } else {
        window.location = link;
      }
    }
  }
};
</script>
